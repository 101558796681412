import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="企業情報"
      description="株式会社アイテクスの企業情報をご案内致します。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">企業情報</h1>
          <h2 className="subtitle">Company</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <StaticImage
          src="../../content/assets/16x9-ookubo-toshimichi.jpg"
          alt="会社概要 大久保利通"
          placeholder="blurred"
          layout="fullWidth"
        />
        <h1 className="title mt-6">会社概要</h1>
        <h2 className="subtitle">Overview</h2>
        <div className="content">
          <div className="table-container">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="row">社名</th>
                  <td>株式会社アイテクス（ITxS Co., Ltd.）</td>
                </tr>
                <tr>
                  <th scope="row">所在地</th>
                  <td>
                    <div>
                      【鹿児島オフィス】 <br />
                      〒892-0842　鹿児島市東千石町2-13 山王ビル2F
                    </div>
                    <div>
                      【本社】 <br />
                      〒892-0871　鹿児島県鹿児島市吉野町2390-114
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">設立</th>
                  <td>2016年01月</td>
                </tr>
                <tr>
                  <th scope="row">代表者</th>
                  <td>代表取締役　永山 邦哉</td>
                </tr>
                <tr>
                  <th scope="row">TEL</th>
                  <td>
                    050-5213-9261（代表） <br />
                    099-800-1527 （管理・経理業務）
                  </td>
                </tr>
                <tr>
                  <th scope="row">FAX</th>
                  <td>099-802-6798</td>
                </tr>
                <tr>
                  <th scope="row">事業内容</th>
                  <td>
                    システム開発・Webシステム開発
                    <br />
                    ITインフラ環境構築／運用支援・保守／コンサルティング
                    <br />
                    ITソフトウェア運用支援／コンサルティング
                    <br />
                    IT関連のアウトソーシング
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <h1 className="title mt-6">アイテクスの歩み</h1>
        <h2 className="subtitle">History</h2>
        <div className="content">
          <div className="table-container">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="row">2014年06月</th>
                  <td>創業　（屋号「アイテックス」）</td>
                </tr>
                <tr>
                  <th scope="row">2016年01月</th>
                  <td>法人化　「株式会社アイテクス」設立</td>
                </tr>
                <tr>
                  <th scope="row">2018年04月</th>
                  <td>
                    Webデータベース「
                    <Link href="https://pleasanter.itxs.biz/" target="_blank">
                      プリザンター
                    </Link>
                    」<br />
                    九州で唯一の認定パートナーとなりサービス提供開始
                  </td>
                </tr>
                <tr>
                  <th scope="row">2018年11月</th>
                  <td>鹿児島の中心である天文館に「鹿児島オフィス」を新設</td>
                </tr>
                <tr>
                  <th scope="row">2019年04月</th>
                  <td>
                    レンタルスペースとシェアオフィス
                    <br />「
                    <Link href="https://bizswitch.jp/" target="_blank">
                      Switch（スイッチ）
                    </Link>
                    」の運営開始
                  </td>
                </tr>
                <tr>
                  <th scope="row">2020年12月</th>
                  <td>
                    お手伝いサポートアプリ「
                    <Link href="https://tascal-39.com/" target="_blank">
                      タスカル
                    </Link>
                    」をリリース
                  </td>
                </tr>
                <tr>
                  <th scope="row">2021年02月</th>
                  <td>
                    体調予測アプリ「
                    <Link href="https://asagao.life/" target="_blank">
                      asagao
                    </Link>
                    」をリリース
                  </td>
                </tr>
                <tr>
                  <th scope="row">2021年11月</th>
                  <td>
                    ネットショップ「
                    <Link href="https://itxs.theshop.jp/" target="_blank">
                      アイテクスオンラインショップ
                    </Link>
                    」をオープン
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="columns is-variable is-5 is-vcentered">
        <div className="column is-two-fifths">
          <div className="has-text-right-tablet">
            <h1 className="title">アクセス</h1>
            <h2 className="subtitle">Access</h2>
            <div className="content">
              <p>
                〒892-0842　鹿児島市東千石町2-13 山王ビル2F
                <br />
                天文館電停から徒歩5分
              </p>
            </div>
          </div>
        </div>
        <div className="column">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6797.160157947919!2d130.54946981107625!3d31.59056129999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x353e67833966c8fb%3A0xc7d4cc3f875a2d88!2z44K344Kn44Ki44Kq44OV44Kj44K5IFN3aXRjaA!5e0!3m2!1sja!2sjp!4v1634093480724!5m2!1sja!2sjp"
            width="100%"
            height="500"
          ></iframe>
        </div>
      </div>
    </section>
  </Layout>
)
export default IndexPage
